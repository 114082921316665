
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/original/original1.png';

useHead({
	"title": "Swagger's Top Pick: Original Favorites' Ethical Custom Swag",
	"meta": [
		{
			"name": "description",
			"content": "Explore Original Favorites' range of premium, ethically-made apparel for your corporate branded swag needs. Discover high-quality, sustainable options with Swagger."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/original-favorites"
		},
		{
			"property": "og:title",
			"content": "Swagger's Top Pick: Original Favorites' Ethical Custom Swag"
		},
		{
			"property": "og:description",
			"content": "Explore Original Favorites' range of premium, ethically-made apparel for your corporate branded swag needs. Discover high-quality, sustainable options with Swagger."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/original-favorites"
		},
		{
			"property": "og:image",
			"content": OgImage
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Brand We Love: Original Favorites
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/original/original1.png' alt="Woman wearing a custom-designed Original Favorites hoodie by Swagger.")
				p Dive into the world of Original Favorites, where ethical manufacturing meets premium quality! Original Favorites is dedicated to creating high-quality apparel with a focus on sustainability and ethical practices.  
				p With an unwavering commitment to quality and a keen eye for detail, Original Favorites stands out as the choice for those who demand the best in both ethics and aesthetics. 
				p At Swagger, we're passionate about partnering with brands that align with our values of excellence and responsibility. Original Favorites is a perfect match, and we're excited to showcase their responsibly-made products in our company branded swag offerings. 
				p Let's explore what makes Original Favorites so special and why we at Swagger are proud to collaborate with them. 

				h2 All About Original Favorites
				img(src='../../images/blog/original/original2.png' alt="Close-up of a custom-designed Original Favorites t-shirts and hoodies by Swagger.")
				p Original Favorites was founded in Denver, Colorado in 2016 by Pete Drago and Dave Boger, who are passionate about creating a more sustainable and ethical apparel industry. They started the company with a clear vision to offer high-quality, environmentally responsible clothing that not only looks great but also has a positive impact on the world. 
				p Pete and Dave bring a unique blend of expertise and values to the brand. Their commitment to sustainability and ethical practices has shaped Original Favorites into a brand that stands out for its dedication to transparency, quality, and environmental stewardship. 
				p Together, they have built a company that isn’t just about selling branded merch, but about promoting a lifestyle that values people and the planet. Their leadership and vision continue to drive Original Favorites towards a future where fashion and sustainability go hand in hand and that’s one of the reasons we here at Swagger are obsessed with them. 

				h2 How Original Favorites Gives Back 
				img(src='../../images/blog/original/original3.png' alt="A valley a part of the Original Favorites sustainable farming program.")
				p Original Favorites isn't just in the business of making killer clothes; they're on a mission to do some serious good in the world. This brand is all about action, not just words. They're teaming up with cool organizations and jumping on board with initiatives that pack a punch in social and environmental causes. They’re all about making a difference. 
				p Let's talk about their commitment to sustainable farming practices. Original Favorites knows that awesome apparel starts with high-quality materials. All their apparel is made from purely organic cotton. By sourcing materials that are kind to the planet, they're making sure that their threads are not only comfy but also conscious. They're all about supporting the farmers who are doing things right, sticking to the greenest and cleanest methods out there. 
				p But the goodness doesn’t end there—Original Favorites is also big on giving back to the community. They're putting their money where their heart is, investing in projects that lift people up and make life better. Whether it's backing educational programs, funding health initiatives, or supporting economic growth, they're all in. It's like every piece of clothing they make is sprinkled with a little bit of magic that helps change lives. 
				p So, when you choose Original Favorites for your corporate branded swag, you're doing more than just sprucing up your brand's look—you're making a statement that your brand is about more than just the bottom line. It's about caring, sharing, and wearing your values on your sleeve—literally. With Original Favorites, your custom swag isn’t just swag—it’s a mark of distinction. 

				h2 Original Favorites' Products
				img(src='../../images/blog/original/original4.png' alt="Assortment of Original Favorites products by Swagger, including pants and shorts.")
				p Original Favorites offers a range of premium apparel that's perfect for corporate swag. Their hoodies are a standout, made from soft, high-quality materials that provide comfort and style. With a focus on durability and sustainability, these hoodies are designed to last and make a statement about your brand's commitment to quality and ethics. 
				p Their t-shirts are equally impressive, offering a perfect blend of comfort, style, and sustainability. Made from organic cotton and designed with a modern fit, these tees are ideal for creating branded merchandise that your team and clients will love to wear. 
				p Original Favorites also offers a selection of hats, bags, and other accessories, all crafted with the same attention to detail and ethical standards. Whether you're looking for casual wear or athleisure to wear in the office, Original Favorites has you covered. 

				h2 Why Swagger Partners with Original Favorites 
				img(src='../../images/blog/original/original5.png' alt="Assortment of Original Favorites products by Swagger, including pants and shorts.")
				p We're thrilled to partner with Original Favorites because they embody everything we stand for at Swagger. Their commitment to ethical manufacturing, sustainability, and premium quality aligns perfectly with our mission to provide our clients with the best corporate branded swag.
				p Choosing Original Favorites for your company swag means choosing a brand that not only elevates your brand image but also supports ethical and sustainable practices. It's a chance to show that your company is all about putting people and the planet first, beyond just the bottom line.
				p With Original Favorites, you're getting more than just apparel—you're getting a symbol of your brand's commitment to excellence and responsibility. And that's something we at Swagger are proud to support.
				
				p At Swagger, we've got all your swag needs covered under one roof. From beautifully branded gifts to ready-to-shop swag stores, we take care of kitting, warehousing, inventory, and on-demand shipping. We're here to partner with you on stylish swag that they'll want to show off and you'll be proud to give. 
				p Looking for more company swag inspo? Dive into Swagger’s <a href="https://giveswagger.com/lookbooks/2024-trends">2024 Trends Lookbook</a>. 
				p Ready to treat your employees or clients to products that inspire them to live more sustainability? Just fill out this short form: <a href="/get-started">Let’s Do This!</a>
				p We’d love to hear from you! Drop us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>
</template>
  