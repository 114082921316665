
<script setup>
import { useHead } from '@unhead/vue';

useHead({
	"title": "",
	"meta": [
		{
			"name": "description",
			"content": ""
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/"
		},
		{
			"property": "og:title",
			"content": ""
		},
		{
			"property": "og:description",
			"content": ""
		},
		{
			"property": "og:image",
			"content": "https://giveswagger.com/assets/swagger-social.jpg"
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/"
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 
	.page__content
		section.section
			.section__content
				.featured

</template>
  