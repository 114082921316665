
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/marine-layer.jpg';

useHead({
	"title": "Swagger Spotlight: Marine Layer Custom Swag",
	"meta": [
		{
			"name": "description",
			"content": "Discover sustainable custom swag at Swagger! We partner with brands like Marine Layer, known for quality and zero-waste innovation. Get comfy, eco-friendly branded merchandise today."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/marine-layer"
		},
		{
			"property": "og:title",
			"content": "Swagger Spotlight: Marine Layer Custom Swag"
		},
		{
			"property": "og:description",
			"content": "Discover sustainable custom swag at Swagger! We partner with brands like Marine Layer, known for quality and zero-waste innovation. Get comfy, eco-friendly branded merchandise today."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/marine-layer"
		},
		{
			"property": "og:image",
			"content": OgImage
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Brand We Love: Marine Layer
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/marine-layer.jpg' alt="Model wearing Marine Layer Corbet Jacket curated by Swagger.")
				p Marine Layer is on a mission to make every step of their process sustainable—from replacing all <a href="https://www.marinelayer.com/pages/impact-report" target="_blank">single-use plastics</a> in packaging to only working with vendors who share their value for equal pay and fair work.   
				p Swagger partners with Marine Layer not only because they are eco-friendly but also because of their commitment to giving back to the community through volunteering and donating directly in the Bay Area—where it all started in a VW Wagon.
				p Let's look at Marine Layer's story and why this laidback California-based brand makes the cut for custom company swag.   
				p
					u Marine Layer Custom T-Shirts and Hoodies
				img(src='../../images/blog/marine-layer/swagger-marine-layer-1.png' alt="Models wearing Marine Layer apparel custom branded Signature Crew and Sunset Hoodie and product images of additional tees and hoodies offered by Swagger as branded merch.")

				h2 About Marine Layer Custom Clothing  
				p Marine Layer builds its San Francisco-based brand on the idea that it should create high quality clothing through a zero-waste process, saving tons of clothing ending up in a landfill.
				p Have you ever had a favorite shirt that you couldn't get rid of? Do you have that one I-can't-live-without-it tee on its last, threadbare legs? We asked Team Swagger and the answer was a unanimous “yes!”
				p Sometimes we simply become attached to our clothes, which can make it challenging to leave them behind—even when they start to fray or that one inch hole becomes a far cry from the trendy ripped look. One sustainable solution to this dilemma is the inspiration behind Marine Layer—the <a href="https://www.marinelayer.com/pages/respun-tees" target="_blank">Re-Spun</a> Program which recycles donated old clothing into new custom clothing. 
				p And Marine Layer takes personalization up a notch with the ability to layer for a unique look—pair a Signature Crew with a Sunset hoodie and you’ve got the perfect duo for those cold mornings that turn into hot days. Marine Layer makes it easy to curate your own style—creating corporate swag to hang onto for good.

				p
					u Marine Layer Custom Corbet
				img(src='../../images/blog/marine-layer/swagger-marine-layer-2.png' alt="Model wearing Marine Layer Corbet Full Zip Vest. Product images of other pullover and jacket custom swag options offered by Swagger.")

				h2 Your Brand Will Look Its Best With Marine Layer Custom Colors
				p Another thing we love about Marine Layer is their Custom Color Program. You can get a selection of Marine Layer favorites in your brand’s exact Pantone color. It’s like magic. Pick a color, any color for your perfect on-brand company swag for your next gift or team event.  Marine Layer features 7 different products ready for you to customize, such as their Custom Dyed Signature Crew, Custom Dyed Sherpa Crew Pullover, and Custom Dyed Signature Lined Blanket.
				p
					u Marine Layer Custom Blankets, Bags &amp; Beanies
				img(src='../../images/blog/marine-layer/swagger-marine-layer-3.png')

				h2 Sustainable Marine Layer Swag for Your Company
				p Swagger believes in the future of sustainability, and Marine Layer custom clothing provides quality custom swag without hurting the environment. The rebirth of your most adored clothing isn’t even the best part—Swagger partners with Marine Layer for sustainable apparel upping your corporate swag game—bringing custom branded merch you can feel great about.
				p When you take a step away from the office—or let’s face it—the house if you’re part of the vast remote workforce, you can celebrate the goodness of Marine Layer with the Weekender Bag or cozy up with your partner or pup with the Corbet Blanket and feel good about your choice.
				p In terms of your corporate swag, Marine Layer clothing takes it to the next level. Their high quality outerwear is perfect for your next company swag giveaway. 
				p Ready to treat your employees or clients to products that inspire them to live more sustainably? Just fill out this short form: <a href="/lets-do-this">Let's Do This!</a>
				p We'd love to hear from you! Send us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>.
</template>
  