
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/bipocbrands/bipoc1.jpg';

useHead({
	"title": "Swagger Shares Top BIPOC Brands for Custom Swag",
	"meta": [
		{
			"name": "description",
			"content": "Explore SUPLMNT, Elemental Drinkware, Ian James Leather, and Harlem Candle Co.—a few of Swagger’s Favorite BIPOC (Black, Indigenous & People of Color) Brands for your custom swag."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/bipocbrands"
		},
		{
			"property": "og:title",
			"content": "Swagger Shares Top BIPOC Brands for Custom Swag"
		},
		{
			"property": "og:description",
			"content": "Explore SUPLMNT, Elemental Drinkware, Ian James Leather, and Harlem Candle Co.—a few of Swagger’s Favorite BIPOC (Black, Indigenous & People of Color) Brands for your custom swag."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/bipocbrands"
		},
		{
			"property": "og:image",
			"content": OgImage
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Swagger’s Favorite BIPOC Businesses You Should Know 
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/bipocbrands/bipoc1.jpg' alt="Image of designer Ian James holding a piece of Ian James Leather.")

				p As a women-owned business, we recognize how important it is to support individuals and brands that may be traditionally underrepresented. At Swagger, we not only take sustainability and eco-friendliness seriously when we partner with a brand, but we also want to know the people behind it—the ones who spent time creating and designing the products.  
				p We handpick top-notch products from suppliers who are all about giving back to the planet and our community. There’s a growing awareness of the importance of diverse representation in the business world including women-owned, minority-owned, and small businesses, after historical and systemic discrimination. It’s about time we looked for and chose corporate gifts and products from businesses that matter. Investing in Black, Indigenous, and People of Color (BIPOC) businesses promotes an environment where a variety of voices and perspectives are heard.
				p On top of that, putting our focus into these brands taps into products or designs you may not know of, and you’ll get a chance to learn people’s stories that are waiting to be shared, while supporting brands you’ll end up swooning over for the long haul. 
				p That’s why Swagger put together a list of our favorite BIPOC owned businesses you should know. Let's chat about six remarkable BIPOC brands that are making waves in the corporate gifting world through innovation, and inclusivity, with company branded swag you’ll want to hang onto. 
				
				h2 1. Ian James Leather
				img(src='../../images/blog/bipocbrands/bipoc2.jpg' alt="Product image of man wearing Ian James Leather Kingsley Backpack in brown.")
				img(src='../../images/blog/bipocbrands/ian-james-tray.jpg' alt="Image of Ian James Leather catch-all trays next to dried flowers.")
				p Ian James Leather, a brand born from the passion and precision of its founder, Ian James, is all about making really cool bags and accessories. It's not just about making high-quality products, it's about making things that last and look awesome. From humble beginnings, he began cutting patterns in leather in his Bronx apartment in early 2013. Now, Ian James can be seen throughout the US and multiple countries. 
				p What makes Ian James Leather stand out? They make things that are a mix of classic and cool. Their bags and accessories are wearable art but also practical with strong angular lines and a double X motif seen on all the designs. The <a href="https://ianjamesmade.com/products/marie-satchel-navy-crossbody-bag">Marie Satchel Mini</a>, a cross-body bag sure to add flair, and the <a href="https://ianjamesmade.com/products/blake-navy">Blake Handbag</a> are a few Swagger faves. 
				p In a world where fancy things can feel off-limits, Ian James Leather does things differently. The mission is to make elegance inclusive—they truly want everyone to feel fancy. By welcoming all kinds of people, Ian James Leather is changing how we think about luxurious bags, so when it comes to corporate branded swag, everyone can enjoy a touch of timeless coolness without breaking the bank.

				h2 2. Elemental
				img(src='../../images/blog/bipocbrands/teakwood.webp' alt="Image of a man holding teakwood Elemental water bottle.")
				p <a href="https://giveswagger.com/elemental">Elemental</a>, founded by engineers and close buddies Seth Inyang and Vinh Lieu, had two totally different views on water. Seth, from the Midwest, thought it was everywhere for farming. Vinh, growing up in Vietnam, knew clean water was a rare gem. In 2016, they kicked off Elemental, starting with a slick reusable water bottle that mixed Seth's creativity and Vinh's tech skills. 
				p Elemental's reusable water bottle, is a creative fusion of Midwestern and Vietnamese influences, cultivating a product that hydrates and narrates cultural stories. The brand's product range continues to showcase diverse drinkware. 
				p Beyond the practical stuff, Elemental's all about bringing cultures together. Its custom branded merch gives back with 3% of their profits going to charities. 
				p They're teaming up with individuals and trusted organizations to make a healthier, better world. Swagger partners with Elemental because they’re making a future where diversity rules and brings us all together.  

				h2 3. Harlem Candle Co.
				img(src='../../images/blog/bipocbrands/harlem_candle_co_cover.jpg' alt="Image of an assortment of Harlem Candle Company candle scents.")
				p The Harlem Candle Company, brought to life by visionary Teri Johnson, is a vibrant tribute to the heart and soul of Harlem. Founded in 2014, this brand isn't just about candles—it's about embracing the rich culture and history of one of New York City's most iconic neighborhoods.  
				p Supporting black-owned businesses like this one is a powerful way to uplift communities and challenge the status quo. Harlem Candle Company products are an ode to Harlem's essence and they captured unique scents in each candle. Some Swagger faves are the floral and fresh “Josephine's Garden" and the warm "Sugar Hill."  
				p These scents help you bring comfort home while nestling in with a warm book while soft jazz plays in the background. Harlem Candle Co. makes candles that give your branded swag a little more class and meaning. 

				h2 4. SUPLMNT
				img(src='../../images/blog/bipocbrands/bipoc4.jpg' alt="Image of person holding SUPLMNT 24 Oz Insulated Water Bottle With Classic Lid in white in front of leather pants and Air Jordan shoes.")
				p SUPLMNT was founded by Jairus Morris, who grew up in the inner-city where drinking high-sugar drinks like Kool-Aid and soda were the norm. As he got older, he realized there wasn’t anybody who taught him or his peers the importance of drinking water and how not drinking it had negative effects on their health later in life.
				p Even more, many water bottle brands existed, but he never noticed anyone in his neighborhood drinking them. This is why he wanted to change the game. Swagger chose SUPLMNT because of its mission of connecting and collaborating with culture, encouraging hydration by making it cool and authentic. SUPLMNT also gives back to the communities who desperately need education and tools for better health.
				p SUPLMNT gives a refresh to the classic water bottle by making it cool, accessible, and trendy. Its big carry loop makes it functional and the rubber sole (like the one you’d find on your favorite tennis shoes) allows for an easy set-down without it tipping over.
				p Swagger loves the SUPLMNT tumbler as well. It’s made for your daily hustle and comes in a number of different colors to compliment your fave outfit. Enjoy a sip of your coffee while you’re out and about.  
				p SUPLMNT is the brand that's got us hyped over at Swagger. They're all about curating the freshest gear and teaming up with brands that share their vibe. When it comes to custom branded merch, they're the ones to watch! 

				h2 Need to round out your gift with thoughtful add-ons? Here’s Swagger’s top BIPOC-owned picks. 

				h3 Teaspressa
				p Female-owned and operated by creator Allison DeVane, Teaspressa grew from the ground up when she pedaled tea around in a laundry cart in 2015 in Phoenix, Arizona. Within a few months, she pitched to the crew of Shark Tank and it catapulted her career. She opened a shop of her own and has expanded past teas into cool mixology (think moscow mule cubes) and barista cubes made from 100% natural ingredients. 
				p It’s a brand Swagger wants you to know about to complete your company swag gift kit—pair the instant mimosa mixology cubes with S’well insulated wine glasses. It’s organic, fair-trade, eco-friendly, and all natural. Teaspressa believes that the little moments matter, and Swagger can definitely get behind that. 
				img(src='../../images/blog/bipocbrands/bipoc3.jpg' alt="Image of Teaspressa’s instant cocktail cubes in a dish with a cocktail-filled martini glass.")

				h3 Aesthete Tea  
				p We love the mother-daughter duo, Briana Thornton and Maggie Cassidy, who created Aesthete Tea in 2017. Aesthete Tea champions conscious living. They want to send a message with their teas to promote a sustainable and mindful lifestyle.  
				p The Portland, Oregon company adds a special touch to your custom swag box. One that you know honors and deeply respects nature and traditional folk healing practices across African, Native American and Celtic cultures through sourcing high-quality herbs, teas, and givesback by educating the community about the ancient teachings. Choose from healing products like Golden Milk or La Vie En Rose to give a unique offering to your friends, employees, or clients. 
				img(src='../../images/blog/bipocbrands/aesthete-tea.jpg' alt="Image of Aesthete Tea’s Herbal Blend Samhain loose leaf with traditional plate and tea spoon.")

				h2 Swagger Celebrates BIPOC Businesses 
				p Supporting BIPOC brands gives us the chance to embrace diverse minority stories, perspectives, and add realness to the business world. They're not just putting out goods—they're crafting stories, missions, and a solid sense of purpose. 
				p Your choices matter! By choosing to support BIPOC brands, you’re shaping an economy that’s all about diversity, creativity, and good vibes.  
				p Are you ready to improve the way you do swag? Swagger is ready to partner with you. Fill out this short form: <a href="/lets-do-this.html">Let's Do This!</a>
				p We'd love to hear from you. Send us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>.
</template>
  