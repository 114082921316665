
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/known-supply/known-supply1.png';

useHead({
	"title": "Known Supply: Customizable & Fair-Trade Custom Swag Curated by Swagger",
	"meta": [
		{
			"name": "description",
			"content": "Discover Known Supply's fair-trade apparel, perfect for corporate branded swag. Swagger helps you personalize high-quality, ethical clothing for your brand."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/known-supply"
		},
		{
			"property": "og:title",
			"content": "Known Supply: Customizable & Fair-Trade Custom Swag Curated by Swagger"
		},
		{
			"property": "og:description",
			"content": "Discover Known Supply's fair-trade apparel, perfect for corporate branded swag. Swagger helps you personalize high-quality, ethical clothing for your brand."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/known-supply"
		},
		{
			"property": "og:image",
			"content": OgImage
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Brand We Love: Known Supply
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/known-supply/known-supply1.png' alt="A man and a woman in Known Supply striped t-shirts curated by Swagger.")
				p Welcome to the world of Known Supply, where fashion meets fairness. Launched with the mission to humanize the apparel industry, Known Supply redefines how products are made and who makes them. They aren't just creating clothes—they're making a statement about how the fashion industry should operate—with transparency, dignity, and respect for everyone involved. 
				p At Swagger, we're about more than just products—we're about meaningful partnerships. We align ourselves with brands that not only exemplify excellence but also operate with a purpose. Known Supply fits this vision perfectly, and we are excited to incorporate their ethically made, customizable apparel into your corporate branded swag. Because when it comes to representing your brand, why not do it with integrity and style? 
				
				h2 All About Known Supply
				img(src='../../images/blog/known-supply/known-supply2.jpg' alt="A Known Supply garment maker holds up her signatured garment tag that will go on the apparel she handmade.")
				p Founded by a trio of social entrepreneurs, Known Supply is pioneering a new standard in the apparel industry. They do this by offering complete transparency—each piece of clothing comes tagged with the signature of the person who made it, offering a personal connection that is rare in the industry. 
				p Imagine this: t-shirts, hoodies, and hats that are not only stylish and comfortable but also carry a powerful story of empowerment and respect. That's what Known Supply offers—a product line that supports fair wages, safe working conditions, and an ethical approach to fashion. 
				p And there's more to Known Supply than just ethical practices. Their commitment to quality is evident in every item they produce. They use organic cotton and other sustainable materials, ensuring that the custom swag is not only good for the people but also for the planet. 

				h2 Known Supply's Commitment to Fair Trade 
				img(src='../../images/blog/known-supply/known-supply3.jpg' alt="Garment workers in a Known Supply warehouse in India.")
				p At the heart of Known Supply's operations is their steadfast dedication to fair trade principles. This commitment means prioritizing the welfare of their workers and the environment over profits. Each Known Supply garment has a tag on it that includes the garment maker’s signature, making each piece personal. By fostering direct relationships with their artisans and providing them with fair wages and benefits, Known Supply uplifts entire communities.
				p Choosing Known Supply for your corporate swag means aligning your brand with values of justice and sustainability. It shows your commitment to quality not just in materials but in human dignity—making your branded merchandise profoundly impactful.

				h2 Known Supply's Range of Products
				img(src='../../images/blog/known-supply/known-supply4.jpg' alt="Man holding a Known Supply Large Weekender bag while wearing a Known Supply t-shirt curated by Swagger.")
				p Known Supply's catalog is diverse, featuring items that can be personalized to reflect your brand's unique identity. From soft organic tees that offer day-long comfort to cozy hoodies that keep the chill away, their products are perfect for creating a unified look for your team or memorable company branded swag for your clients. 
				p Their customization capability is a standout feature. With Known Supply, you can have your company logo or a custom message hand-stitched or printed, turning basic apparel into bespoke pieces that speak volumes about your brand’s values and aesthetic. 

				h2 Why Swagger Loves Known Supply
				img(src='../../images/blog/known-supply/known-supply5.jpg' alt="A Known Supply garment maker holds up a piece of clothing they made with their signature tag stitched in.")
				p Our love for Known Supply is deep-rooted in our shared values of excellence and ethical practices. By partnering with them, we ensure that every piece of swag we provide not only looks good but feels good too—ethically and tangibly.
				p Known Supply doesn’t just supply clothes—they supply a vision of a better world, one piece of apparel at a time. This aligns seamlessly with our mission at Swagger—to deliver not just products, but promises of quality, care, and integrity.


				p Swagger brings all your swag needs under one roof. We create beautifully branded gifts, build ready-to-shop swag stores, and take care of kitting, warehousing, inventory, and on-demand shipping. We’re here to partner with you on stylish swag that they’ll want to show off and you’ll be proud to give.
				p Looking for more company swag inspo? Dive into Swagger’s <a href="https://giveswagger.com/lookbooks/2024-trends">2024 Trends Lookbook</a>.
				p Ready to treat your employees or clients to products that inspire them to live more sustainability? Just fill out this short form: <a href="/get-started">Let’s Do This!</a>
				p We’d love to hear from you! Drop us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>
</template>
  