
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/karst/karst.png';

useHead({
	"title": "Swagger Celebrates Karst Notebooks",
	"meta": [
		{
			"name": "description",
			"content": "Explore sustainable innovation with Swagger. Join us in celebrating Karst notebooks—tree-free, waterproof, and socially impactful. Use the future of responsible stationery for your custom branded merchandise."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/karst"
		},
		{
			"property": "og:title",
			"content": "Swagger Celebrates Karst Notebooks"
		},
		{
			"property": "og:description",
			"content": "Explore sustainable innovation with Swagger. Join us in celebrating Karst notebooks—tree-free, waterproof, and socially impactful. Use the future of responsible stationery for your custom branded merchandise."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/karst"
		},
		{
			"property": "og:image",
			"content": OgImage
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Brand We Love: Karst
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/karst/karst.png' alt="Karst notebooks curated by Swagger shown in varying sizes and colors.")
				p In a world where tradition often reigns supreme, Karst dared to ask the questions that many overlooked. <i>Why are most notebooks made of trees? Why are they not waterproof? Why can’t we come up with new ways of making paper?</i> These questions led to a groundbreaking solution that redefines the traditional notebook.

				h2 Karst x Swagger: A Partnership with Purpose  
				p At Swagger, the choice to partner with brands is a deliberate one. It's not just about the swag—it's about partnering with brands that bring innovation and purpose to the forefront. Enter Karst—a brand that disrupted the notebook category and left a lasting mark on the minds of both Swagger and our clients. The partnership isn’t about creating throwaway, conventional merch, instead it celebrates a shared commitment to redefining industries.
				
				img(src='../../images/blog/karst/swagger-karst-2.png' alt="Stone turning into the innovative paper in Karst notebooks with graphic saying “We are committed to do better. Each metric tonne of Karst Stone Paper saves 2770 litres of water, equivalent to 50 months of water consumption per person. Certified B Corporation.")
				h2 The Category Disruptor: All About Karst  
				p Karst started in Sydney, Australia, where two visionary entrepreneurs, Jon Tse and Kevin Garcia, set out to redefine paper for the 21st century. Their journey took a significant turn during a vacation to Taiwan, where they discovered a Taiwanese industrial manufacturing company using an alternative to wood pulp —recycled stone dust, or calcium carbonate. This material offered a tree-free more sustainable option and made the paper water resistant. With that combo, Karst was born and a new way to engineer paper. 

				img(src='../../images/blog/karst/swagger-karst-3.png' alt="Karst notebook open with Karst Woodless Pencils shown in use.")
				h2 The Power of Karst Product
				p What sets Karst apart is its revolutionary approach to the impact of its products—one you can actually see and feel. Crafted from recycled stone dust, the stone paper notebooks undergo a meticulous process involving resin, heat, and pressure. The result? A sustainable, waterproof, tear-resistant and remarkably resilient notebook. The velvet-like texture of the paper adds a sensory delight, making every stroke of the pencil a seamless experience.  
				p Karst stone paper doesn’t use any harsh chemicals to make this process perfect. So you don’t have to worry about your new favorite hardcover notebook being toxic for the environment with most traditional notebooks having to use acids and bleach to get the final result. Because of this, the Karst notebooks make for a perfect corporate gift. 
				p From sketchbooks to planners, Karst offers sustainable swag that caters to diverse needs. The carbon neutral brand has the ability to customize stylish notebooks with a myriad of colors and styles that distinguishes them in a market saturated with the typical. Even their writing utensils, like woodless pencils made of 100% graphite, align with their commitment to sustainability, offering an eco-friendly alternative without compromising performance.

				img(src='../../images/blog/karst/swagger-karst-4.png' alt="Product images of Karst Stone Bound Notebooks in 2 colors starting at $24, Pocket Stone Paper Notebook starting at $10, and Woodless Graphite Pencils starting at $22, curated by Swagger.")
				h2 Swagger Celebrates The Karst Impact  
				p Karst notebooks go beyond just being a writing companion—they embody sustainability. With 60% lower carbon emissions than traditional tree paper, Karst takes responsibility further by offsetting the remaining emissions and planting a tree for each notebook sold. The commitment extends to their factory, earning them the Cradle-to-Cradle certification for end-to-end sustainability from the products start to finish.  
				p Disposing of a Karst notebook is as environmentally conscious as its production. Toss it into the plastics recycling bin, and the paper will be burned, allowing the dust and resin to separate. No recycling access? No worries—the Karst notebook naturally breaks down in 6 to 12 months when exposed to sunlight (another aha! Jaw-wide-open moment). It's a full-circle sustainability approach that reflects Karst's commitment to minimizing its environmental footprint.

				h2 Why Swagger Loves Karst  
				p The partnership between Swagger and Karst goes beyond a business collaboration. It's rooted in shared beliefs and goals—a commitment to sustainability, the creation of high-quality custom swag with purpose, and an unwavering priority to protect the planet.
				p Both Swagger and Karst share a mindset that thinks "outside the box," where innovation and purpose intersect. Choosing Karst as a partner isn’t just a business decision—it's a celebration of a shared journey toward a more sustainable and conscious future. Choose Karst for your next company swag gift and give back.  
				p Are you ready to improve the way you do swag? Swagger is ready to partner with you. Fill out this short form: <a href="/lets-do-this">Let's Do This!</a>
				p We’d love to hear from you! Send us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>.
</template>
  