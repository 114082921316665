export function setMarketingCookie(query) {
	const targets = ['utm_source', 'utm_medium', 'utm_campaign', 'adgroupid', 'utm_content', 'utm_term', 'gclid'];

	let paramObj = {};

	for (const param of targets) {
		if (query[param]) {
			paramObj[param] = query[param];
		}
	}

	let expires = new Date();
	expires.setTime(expires.getTime() + (60*60*24*31));

	// only write the cookie if we found one of the marketing parameters (prevents overwriting things on the next page with a blank object)
	if (Object.keys(paramObj).length > 0) {
		const cookieVal = `paramObj=${JSON.stringify(paramObj)}; expires=${expires.toGMTString()}; path=/;`;
		document.cookie = cookieVal;
		console.log(cookieVal)
	}
}