
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/1-percent/1-percent1.png';

useHead({
	"title": "Swagger's 1% for the Planet Impact: Action for Change",
	"meta": [
		{
			"name": "description",
			"content": "Discover how Swagger's commitment to 1% for the Planet and your support is driving positive change and contributing to custom swag with a sustainable future."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/1-percent-for-the-planet"
		},
		{
			"property": "og:title",
			"content": "Swagger's 1% for the Planet Impact: Action for Change"
		},
		{
			"property": "og:description",
			"content": "Discover how Swagger's commitment to 1% for the Planet and your support is driving positive change and contributing to custom swag with a sustainable future."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/1-percent-for-the-planet"
		},
		{
			"property": "og:image",
			"content": OgImage
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Impact Through Action: Swagger's Commitment to Donating 1% for the Planet
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/1-percent/1-percent1.png' alt="Image of 1% for the Planet logo in front of a snowy mountain top.")
				p At Swagger, we’ve been 1% for the Planet members since day one so being a part of this community isn't just a part of our day-to-day operations—it's woven into the very fabric of our existence. This commitment isn't just about making donations—it's about being part of a global community that's dedicated to healing our planet. It's a testament to our belief that every action, no matter how small, can spark a wave of change. 
				p We're diving into the core of Swagger's commitment to environmental sustainability, powered by our partnership with 1% for the Planet. This isn't just a badge we wear—it's the driving force behind every decision and action. From curating eco-friendly custom swag to forming meaningful partnerships, our dedication to the planet is integral to our identity.  
				p We let our team decide where our donations go, making our impact even more personal and impactful. Join us as we explore the real stories of change, fueled by our unwavering commitment to making a difference. Let’s dive in. 

				h2 Why 1% for the Planet? 
				p Swagger is serious about making a positive impact, which is why we're drawn to the 1% for the Planet story. It all started in 2002 when Yvon Chouinard of Patagonia and Craig Mathews of Blue Ribbon Flies decided to turn a simple idea into a global movement. They proposed that businesses donate 1% of their annual sales to environmental causes, making sustainability an integral part of their operations. 
				p Joining 1% for the Planet was an obvious choice for us. It's a way to ensure that every transaction we make also contributes to the well-being of our planet. It's about more than just giving back—it's about being part of a community that's committed to driving real change. So, while we're in the business of creating standout custom branded swag, we're also in the business of protecting Mother Nature—one percent at a time. 

				h2 Team Swagger’s 1% for the Planet Impact 
				p Swagger understands the power of giving back, and we're all about making a difference in the world. That's why we decided to set aside 1% of our annual sales to support causes that are close to our hearts. We wanted to make sure that our contribution was not just a corporate decision, but a personal one too.
				p So, we turned to our amazing team members and asked each of them to pick a nonprofit that really resonates with them. It was all about finding a group from the 1% for the Planet network that spoke to their passions and values. Doing it this way allowed us to create a diverse and meaningful list of organizations, each reflecting the unique interests and commitments of our team.

				h2 Team Swagger’s Nonprofit Picks 
				p So, in celebration of our impact, here's a look at the incredible organizations our team chose, showcasing our collective dedication to making a positive impact in the world: 
				
				h3 GrowNYC 
				.wide-image
					img(src='../../images/blog/1-percent/1-percent2.png' alt="")
				
				p <a href="https://www.grownyc.org/" target="_blank">GrowNYC</a> is a hands-on non-profit which improves New York City's quality of life through environmental programs that transform communities block by block and empower all New Yorkers to secure a clean and healthy environment for future generations. 
				p Why It Matters: By providing access to farm-fresh produce through farmers' markets, GrowNYC promotes healthier, greener lifestyles and supports local agriculture, contributing to the overall sustainability of urban environments. 

				h3 Roundhouse Aquarium
				.wide-image
					img(src='../../images/blog/1-percent/1-percent3.png' alt="")
				
				p <a href="https://roundhouseaquarium.org/" target="_blank">The Roundhouse Aquarium</a> teaches visitors about the wonders of the ocean, inspiring conservation and respect for marine life. 
				p Why It Matters: Education is a powerful tool in conservation. The Roundhouse Aquarium's efforts to inspire and educate the public play a crucial role in the protection of marine environments. 

				h3 Wild Orca 
				.wide-image
					img(src='../../images/blog/1-percent/1-percent4.png' alt="")
				p <a href="http://www.wildorca.org/" target="_blank">Wild Orca</a> is committed to the research and conservation of orcas, with a focus on the Southern Resident killer whales of the Pacific Northwest. 
				p Why It Matters: The Southern Resident killer whales are an iconic and endangered species. Wild Orca's research and advocacy work is crucial for understanding and mitigating the threats they face, such as pollution and food scarcity. 

				h3 Nuestros Pequeños Hermanos 
				.wide-image
					img(src='../../images/blog/1-percent/1-percent5.png' alt="")
				p <a href="http://nphusa.org/" target="_blank">Nuestros Pequeños Hermanos</a> provides a loving home and education for orphaned and abandoned children in Latin America and the Caribbean. 
				p Why It Matters: By supporting the well-being and development of children, Nuestros Pequeños Hermanos is breaking the cycle of poverty and creating opportunities for a better future. 

				h3 Farming Hope 
				.wide-image
					img(src='../../images/blog/1-percent/1-percent6.png' alt="")
				p <a href="https://farminghope.org/" target="_blank">Farming Hope</a> empowers individuals facing food insecurity and homelessness through culinary training and employment, while also addressing food waste. 
				p Why It Matters: Farming Hope tackles multiple social and environmental issues by providing opportunities for those in need and promoting sustainable food practices, creating a more equitable and responsible food system. 

				h2 Swagger’s Looking Forward 
				p Embarking on our journey with 1% for the Planet is far more than just ticking a box or fulfilling a pledge. It's about embracing a whole new lifestyle—one that's rooted in responsibility, advocacy, and a deep-seated commitment to our environment. It's a journey that's reshaping the way we think, act, and interact with the world around us. 
				p At Swagger, we're not just proud of the contributions our team has made; we're inspired by them. Each contribution is a testament to our collective passion and dedication to making a tangible difference. It's a reflection of our unwavering commitment to not just talking the talk but walking the walk. And we’re not stopping there, read more about <a href="https://giveswagger.com/2023-impact">Our Impact</a> here to find out other ways we continue to give back. 
				p Looking ahead, we're filled with excitement and anticipation for the positive impact we can achieve together. We're not just aiming for incremental changes; we're setting our sights on transformative, groundbreaking strides that will leave a lasting mark on our planet. So, here's to the journey ahead—may next year be even bigger. 
				
				h2 Continuing Our Journey: More Nonprofit Picks from Team Swagger
				h3 Save the Sharks 
				p <a href="http://www.savethesharksorg.com/" target="_blank">Save the Sharks</a> is focused on shark conservation and education to change public perception and save these vital apex predators. 
				p Why It Matters: Sharks play a crucial role in maintaining the balance of marine ecosystems. Save the Sharks works to protect these misunderstood creatures from overfishing, finning, and habitat destruction, ensuring the health of our oceans. 

				h3 Reef Renewal USA
				p <a href="http://reefrenewalusa.org/" target="_blank">Reef Renewal USA</a> is dedicated to the conservation of our ocean's coral reef ecosystems through restoration, research, and education. 
				p Why It Matters: Coral reefs are vital to marine life and coastal communities. Reef Renewal USA's efforts in coral restoration and public engagement help protect these endangered ecosystems and promote sustainable ocean practices. 

				h3 SOMAR Associação de Conservação Marinha e Bioacústica 
				p <a href="https://somarbio.pt/" target="_blank">SOMAR</a> is dedicated to marine conservation and research in Portugal, with a focus on dolphins and whales in the Algarve Coast region. 
				p Why It Matters: By conducting research and promoting ocean literacy, SOMAR plays a vital role in protecting marine mammals and their habitats, contributing to the overall health of the marine ecosystem. 

				h3 Lindsay Wildlife Experience 
				p <a href="https://lindsaywildlife.org/" target="_blank">Lindsay Wildlife Experience</a> connects people with wildlife to inspire responsibility and respect for the world we share. 
				p Why It Matters: As a wildlife rehabilitation center and educational facility, Lindsay Wildlife Experience plays a crucial role in wildlife conservation and public awareness, teaching the community about the importance of coexisting with nature. 

				h3 Whale and Dolphin Conservation 
				p <a href="http://www.whales.org/" target="_blank">Whale and Dolphin Conservation</a> is the leading global charity dedicated to the conservation and protection of whales and dolphins. 
				p Why It Matters: Whales and dolphins are not only important for marine biodiversity but also for the health of the entire planet. Their conservation is vital for maintaining the balance of marine ecosystems and combating climate change. 

				h3 Coral Reef Alliance 
				p <a href="http://www.coral.org/" target="_blank">The Coral Reef Alliance</a> is an environmental NGO that is on a mission to save the world's coral reefs. 
				p Why It Matters: Coral reefs are the backbone of many marine ecosystems and are crucial for the health of our oceans. The Coral Reef Alliance's work in research and conservation is key to protecting these vital habitats. 

				h3 Pacific Wildlife Care 
				p <a href="http://www.pacificwildlifecare.org/" target="_blank">Pacific Wildlife Care</a> is dedicated to the rescue, rehabilitation, and release of injured and orphaned wildlife on the Central Coast of California. 
				p Why It Matters: Wildlife rehabilitation is essential for the preservation of biodiversity. Pacific Wildlife Care's work ensures that injured and orphaned animals receive the care they need to return to their natural habitats. 

				h3 Heal the Bay 
				p <a href="http://www.healthebay.org/" target="_blank">Heal the Bay</a> is an environmental non-profit dedicated to making the coastal waters and watersheds in Greater Los Angeles safe, healthy, and clean. 
				p Why It Matters: Clean water is essential for both human and marine life. Heal the Bay's advocacy, education, and cleanup efforts are vital for protecting the health of coastal and inland waterways. 

				h3 Oceana 
				p <a href="https://oceana.org/corporate-partnership/" target="_blank">Oceana</a> is dedicated to protecting and restoring the world's oceans on a global scale through targeted policy campaigns. 
				p Why It Matters: Oceans are critical to our survival, supporting biodiversity and regulating climate. Oceana's work in policy advocacy and conservation is crucial for ensuring the health and sustainability of our planet's oceans. 

				h3 Save the Children US 
				p <a href="https://www.savethechildren.org/" target="_blank">Save the Children US</a> is committed to ensuring every child has the opportunity to learn, grow, and thrive by providing education, health care, and protection services. 
				p Why It Matters: Children are the future, and their well-being is essential for global development and prosperity. Save the Children's efforts in supporting children's rights and needs are vital for creating a better world for all. 





				p Swagger brings all your swag needs under one roof. We create beautifully branded gifts, build ready-to-shop swag stores, and take care of kitting, warehousing, inventory, and on-demand shipping. We’re here to partner with you on stylish swag that they’ll want to show off and you’ll be proud to give.
				p Ready to treat your employees or clients to products that inspire them to live more sustainability? Just fill out this short form: <a href="/get-started">Let’s Do This!</a>
				p We’d love to hear from you! Drop us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>
</template>
  