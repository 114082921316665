
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/plastic-free/tote-1.png';

useHead({
	"title": "10 Ways to Go Plastic-free With Your Swag",
	"meta": [
		{
			"name": "description",
			"content": "In support of Plastic Free July, we've rounded up our favorite 10 plastic-free, eco-friendly swag items that prove you can be kind to the planet without compromising on quality and style."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/plastic-free-july"
		},
		{
			"property": "og:title",
			"content": "10 Ways to Go Plastic-free With Your Swag"
		},
		{
			"property": "og:description",
			"content": "In support of Plastic Free July, we've rounded up our favorite 10 plastic-free, eco-friendly swag items that prove you can be kind to the planet without compromising on quality and style."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/plastic-free-july"
		},
		{
			"property": "og:image",
			"content": OgImage
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 10 Ways to Go Plastic-free With Your Swag
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/plastic-free/tote-1.png')
				p Plastic can cause a lot of harm to both people and the environment. Most plastics are thrown away after a single use, are made from fossil fuels, and the chemicals in plastics get absorbed by humans and marine life through food and water. 
				p In support of Plastic Free July, we've rounded up our favorite 10 plastic-free, eco-friendly swag items that prove you can be kind to the planet without compromising on quality and style.

				h2 1. The Market Bag from Apolis
				img(src='../../images/blog/plastic-free/apolis-market-bag.png')
				p This best-selling Market Bag from Apolis will turn heads and can be stylishly personalized with text of your choice and your brand's logo.  Made from natural jute, the Market Bag features a soft leather handle and a spill-proof inner lining, making it easy to say farewell to those flimsy plastic grocery bags forever.  These large bags are great for road trips, beach days, and gifts. Each bag from Apolis is handcrafted by working mothers in Bangladesh who receive Fair Trade wages, annual profit dividends, and a retirement fund, so your purchase helps break a poverty-cycle.
				
				h2 2. Rosemary and Cucumber Rooted Candle
				img(src='../../images/blog/plastic-free/rooted-candle.png')
				p We love everything about this unique gift that glows and grows. It starts as this lovely, scented soy-blend candle and transforms into a ceramic planter to grow the included herb and flower seeds.  Choose from light and refreshing scents like rosemary and cucumber, thyme and tonka bean, and lavender and cedarwood. The candle and seed packet are packaged in a beautifully designed, plastic-free box for easy gifting.
				
				h2 3. Cloudy Blue GOTS Organic Cotton Hoodie
				img(src='../../images/blog/plastic-free/gots-hoodie.png')
				p You can't go wrong with anything from Original Favorites. Think luxury, crafted apparel with a conscience.  This certified organic, ultra-soft cotton fleece has a cozy interior, unisex fit, and features flat-knit side ribbing for added flexibility.  They take special care to use no toxic dyes, chemical fertilizers, or formaldehyde, and every garment is produced in a windmill powered, Fair Trade Certified facility.
				
				h2 4. Shopper Tote from Out of The Woods  
				img(src='../../images/blog/plastic-free/tote-1.png')
				p We are obsessed with this versatile sustainable cooler/lunch tote that is perfect for a beach day or your farmer's market haul.  Made from Supernatural Paper and rope handles, it is ultra-light, sustainable, vegan, and even machine-washable. This tote is also FSC®-certified, which means it comes from responsibly managed forests, and has an 84% lighter carbon footprint compared to leather.  Perfect as an employee or customer gift on its own or pair it with a few summer favorites and spoil your team.
				
				h2 5. Bamboo &amp; Ceramic Bottle
				img(src='../../images/blog/plastic-free/bamboo-ceramic-bottle.png')
				p The mixed ceramic and natural bamboo materials paired with the beautiful modern print on this bottle make this our new favorite accessory that will keep you hydrated all day. We love the wide mouth of the bottle which makes it easy to add ice cubes and how this easily slides into cupholders so we can take our drinks on the go.

				h2 6. Natural Bamboo Cutting board 
				img(src='../../images/blog/plastic-free/bamboo-cutting-board.png')
				p This gorgeous, all-natural bamboo cutting board is a stunning way to showcase your brand and great alternative to a plastic cutting board. Use it as a durable, go-to board to chop up veggies and meal prep one night and as a beautiful way to serve a cheeseboard for guests another night.  With a slim design, it's easy to store away when you're done cooking. And because bamboo self-regenerates from its own root system, so there is no need to replant it making it the perfect sustainable wood alternative.
				
				h2 7. Nesting Storage Containers from Zero-waste
				img(src='../../images/blog/plastic-free/nesting-containers.png')
				p Perfect for camping, picnics, and daily lunches, this set of 3 Round Nesting Trio Containers are the perfect plastic-free solution to store your snacks and lunches.  Featuring stainless steel and flexible, colorful silicone lids, they are easy to open, leakproof, and are both dishwasher and freezer safe. This makes them environmentally friendly and a no-brainer for meal prepping and storing leftovers. Pair them with a plastic-free lunch bag and bamboo cutlery for a complete lunch set.
				
				h2 8. Insulated Water Bottle from W&amp;P
				img(src='../../images/blog/plastic-free/insulated-bottle.png')
				p You can find us sipping matcha lattes out of this elegant, functional, and plastic-free Porter Insulated Bottle all summer long. With edge-to-edge ceramic coating, every sip has a clean, metal-free taste. The double-walled, vacuum insulated stainless-steel core keeps drinks magically hot for 12 hours and cold for 24 hours, making it a year-round gift option your team will swoon over.  It also features a thoughtfully designed drink through lid that won't splash or leak as you sip, so you can toss it in your bag as you dash for the subway. 
				
				h2 9. Stone Paper Notebook from Karst
				img(src='../../images/blog/plastic-free/stone-paper-notebook.png')
				p You'll want to take this tree-free Stone Paper Notebook with you for whenever inspiration strikes. Yes, we said stone. Karst notebooks are made from 100% sustainably recycled stone paper and we can't get over how incredibly smooth the paper is to the touch. Both the flexible vegan leather cover and the paper are waterproof, so you don't have to worry about coffee rings or spills destroying your ideas and doodles.
				
				h2 10. Go Plastic-free with Branded Paper Tape
				img(src='../../images/blog/plastic-free/branded-paper-tape.png')
				p From day one, Swagger has used branded paper tape as part of our commitment to offering affordable, curbside recyclable packaging for our customers. And what's not to love? You can print your brand in rich, full color on branded paper tape, bringing a unique custom feel without the cost.  Made from craft paper and reinforced with fiberglass strands, your tape will be stronger than plastic tape and eco-friendly, a true win-win.
				
				p <b>Bonus!</b> We wanted to give a special shout out to one of our favorite apparel partners, <a href="https://ascolour.com/removing-plastic">AS Colour</a> who not only raises the bar on quality and design with its garments, but is on a journey to remove plastic from their entire supply chain. This is no easy feat and they are going the extra mile to consciously remove plastic and reduce their use. This includes removing their plastic duster bags, using cardboard garment hooks, and removing shrink wrap from their pallets. 
				p <b>See something you love?</b> We'd love to share more inspo and talk eco-friendly corporate gift ideas with you. Fill out this short form to get started: <a href="/get-started.html">Let's Do This.</a>
				p Swagger is a women-owned swag distributor, proud member of 1% for the Planet member and certified carbon-free business. 

</template>
  