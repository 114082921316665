
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/tentree/tentree1.png';

useHead({
	"title": "Tentree: Elevate Your Custom Swag with Eco-friendly Style",
	"meta": [
		{
			"name": "description",
			"content": "Explore Tentree's chic and eco-conscious apparel for your corporate branded swag. Opt for both sustainability and fashion with Swagger's handpicked collection."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/tentree"
		},
		{
			"property": "og:title",
			"content": "Tentree: Elevate Your Custom Swag with Eco-friendly Style"
		},
		{
			"property": "og:description",
			"content": "Explore Tentree's chic and eco-conscious apparel for your corporate branded swag. Opt for both sustainability and fashion with Swagger's handpicked collection."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/tentree"
		},
		{
			"property": "og:image",
			"content": OgImage
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Brand We Love: Tentree 
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/tentree/tentree1.png' alt="Two people standing on the shore wearing the customized Tentree Nimbus Long Waterproof Rain Jacket by Swagger.")
				p Step into the world of Tentree, where fashion and sustainability go hand in hand! Tentree is all about showing that you can have stylish, comfortable clothing that's also kind to the planet. 
				p At Tentree, quality and sustainability aren't just buzzwords—they're at the heart of everything they do. And that's something we really admire at Swagger. We're all about connecting with brands that share our love for great design and our commitment to making a positive impact. 
				p That's why we're thrilled to have Tentree as part of our corporate swag lineup. Their eco-friendly and chic apparel is a perfect match for our values. 
				p So, let's take a closer look at what makes Tentree so special and why we're so excited to partner with them. 

				h2 All About Tentree
				img(src='../../images/blog/tentree/tentree2.png' alt="A Tentree Organic Cotton Juniper Blanket draped over a white chair in front of a cozy fireplace, can be designed and curated by Swagger.")
				p Tentree, named for its mission was founded with the goal to plant ten trees for every item sold. With that in mind, Tentree is more than just an apparel brand—it's a movement towards a more sustainable and greener future. Tentree's products are made from environmentally friendly materials, like organic cotton, recycled polyester, and TENCEL™ Lyocell, ensuring that each piece is not only comfortable but also kind to the planet.
				p Tentree's commitment to sustainability doesn't stop at their products. They’re dedicated to transparency and ethical manufacturing, making sure their clothing is produced in fair, safe, and humane conditions. This aligns perfectly with Swagger's mission to provide high-quality, sustainable custom swag.
				p By offering products that are both stylish and eco-friendly, Tentree sets a new standard in the fashion industry. Their apparel, known for its comfort, durability, and sustainable materials, exemplifies the brand's dedication to high quality branded merch.

				h2 How Tentree Gives Back
				img(src='../../images/blog/tentree/tentree3.png' alt="Image of a lush forest from https://www.tentree.com/")
				p Tentree’s environmental and social initiatives go beyond planting trees. They're about restoring biodiversity and empowering communities, making a real difference in the world.
				p One nonprofit that’s close to our hearts here at Swagger is the <a href="https://www.edenprojects.org/">Eden Reforestation Projects</a>. They go beyond planting trees to transform lives by providing jobs and restoring ecosystems. The employee local workers to plants trees and turn barren lands into lush forests. It's truly impactful work that aligns perfectly with our values.
				p Choosing Tentree for your corporate swag sends a powerful message. It shows that your brand is about action, not just words. You're not just making a fashion statement with your corporate branded merch—you're making a statement about your commitment to the planet and its people.

				h2 Why Swagger Loves Tentree
				img(src='../../images/blog/tentree/tentree4.png' alt="Woman wearing Women's Tentree Bamone Sweatpant designed by Swagger.")
				p At Swagger, we're all about teaming up with brands that bring more to the table than just top-notch products. We look for partners who share our commitment to doing right by the planet and its people. That's where Tentree comes in. They're not just about fashion—they're about fashion with a purpose. 
				p Their forward-thinking approach to crafting eco-conscious apparel really strikes a chord with us. That's why we're excited to feature their products in our company branded swag offerings. When our clients pick Tentree, they're not just choosing style and comfort. They're also making a positive impact on the world. 
				p So, what's the deal with our love for Tentree? It's simple. They've nailed the perfect mix of trendy design, environmental stewardship, and positive social influence. For clients seeking company-branded swag that's as impactful as it is appealing, Tentree is our go-to. 

				h2 Choose Tentree for Your Custom Swag 
				img(src='../../images/blog/tentree/tentree5.png' alt="Man wearing a custom branded Tentree beanie by Swagger.")
				p At Swagger, we've got a soft spot for Tentree! They're our go-to when it comes to dishing out swag that's not only cool and high-quality but also kind to the planet. Tentree nails it every time with their mix of eco-friendly vibes and on-point design. 
				p Tentree's company swag is a perfect match for our vibe—all about top-notch quality and sustainability. They're detail-oriented, just like us, making sure every piece of apparel isn't just a treat for the eyes but also a high-five to Mother Earth. 
				p So, when you pick Tentree for your branded merchandise, you're not just getting clothes. You're getting a statement—a blend of style, quality, and eco-consciousness that really speaks volumes about your brand. It's a bold way to show the world what you stand for.

				p At Swagger, we've got all your swag needs covered under one roof. From beautifully branded gifts to ready-to-shop swag stores, we take care of kitting, warehousing, inventory, and on-demand shipping. We're here to partner with you on stylish swag that they'll want to show off and you'll be proud to give. 
				p Looking for more company swag inspo? Dive into Swagger’s <a href="https://giveswagger.com/lookbooks/2024-trends">2024 Trends Lookbook</a>. 
				p Ready to treat your employees or clients to products that inspire them to live more sustainability? Just fill out this short form: <a href="/get-started">Let’s Do This!</a>
				p We’d love to hear from you! Drop us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>

</template>
  