<script setup>
import { ref } from "vue"
import { useSticky } from "@static/compose"
import { useRoute } from "vue-router";

const { onStick, onUnstick } = useSticky()
const header = ref(null)
const route = useRoute();

onStick(() => {
	header.value.classList.add("sticky")
})

onUnstick(() => {
	header.value.classList.remove("sticky")
})

</script>


<template lang="pug">
header.header.header--main#mainHeader(ref="header")
	.header__logo
		.logo.logo__full
			router-link(to="/")
				img(src="../../images/swagger-logo-blue.png" alt="Give Swagger Company Logo in Blue")
		.logo.logo__mobile
			router-link(to="/")
				img.mobile(src="../../images/swagger-circle.png" alt="Give Swagger Simplified Company Logo in Blue")
	.header__cta
		router-link.btn.btn--primary(to="/get-started" v-if="route.name !== 'getStarted'") Let's Do This
	.header__menu-trigger
		button.menuTrigger(v-menu-trigger aria-label="Open Menu")
			.icon
				include ../../images/icons/burger.svg
</template>