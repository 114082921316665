
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/allmade/allmade1.jpg';

useHead({
	"title": "Swagger Celebrates Allmade: Eco-friendly T-shirts for Your Custom Swag",
	"meta": [
		{
			"name": "description",
			"content": "Meet Allmade apparel: Cool tees making a positive difference! Swagger is shaking up your corporate branded swag with eco-friendly t-shirts. Make a statement and do good together."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/allmade-apparel"
		},
		{
			"property": "og:title",
			"content": "Swagger Celebrates Allmade: Eco-friendly T-shirts for Your Custom Swag"
		},
		{
			"property": "og:description",
			"content": "Meet Allmade apparel: Cool tees making a positive difference! Swagger is shaking up your corporate branded swag with eco-friendly t-shirts. Make a statement and do good together."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/allmade-apparel"
		},
		{
			"property": "og:image",
			"content": OgImage
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Brand We Love: Allmade
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/allmade/allmade1.jpg' alt="Image of woman in Allmade Unisex Organic Cotton Tee designed by Swagger.")
				p Meet the brand creating a positive impact, one amazing t-shirt at a time. Allmade was started in 2017 by regular people, for regular people. It all began with the idea to create t-shirts that were sustainable, functional, stylish, and ethical—from the ground up. Allmade didn't want to become part of the fast-fashion problem they witnessed firsthand at previous companies. 
				p At Swagger, we choose to partner with the best of the best brands. Why? Because the brands behind the swag we create are of equal importance and what you put your logo on really matters. Allmade's been tossing tradition out the window, putting sustainability and quality front and center since its inception and Swagger loves custom swag that’s a break from the norm. 
				p Allmade’s mission? To shake up the industry and prove that style and sustainability aren't mutually exclusive. We’re thrilled to introduce you to Allmade apparel. Let’s dive into why it makes the cut for custom branded swag. 
				
				img(src='../../images/blog/allmade/allmade2.png' alt="Allmade Women’s Tri-Blend Long Sleeve Tee, Unisex Long Sleeve Recycled Blend Tee, Unisex Organic French Terry Crewneck Sweatshirt, Unisex Organic Cotton Tee, Unisex Organic French Terry Pullover Hoodie, Unisex Organic French Terry Full-zip Hoodie curated by Swagger")
				h2 All About Allmade 
				p The Allmade story starts with their ten founders traveling through Haiti together and experiencing first-hand the economic and environmental challenges for garment workers. They witnessed how shady and unethical the practice of simply making clothing can be. Through exploration and education, the founders learned the positive impact they could have on people and the planet by doing it differently.  
				p They were done playing by the rules and so, Allmade was born. 
				p Creating a better t-shirt was also about creating better lives. It was at that moment they realized creating dignified fair wage jobs would empower the people of Haiti to take care of themselves and their families. This would lead to breaking the cycle of poverty and child abandonment.
				p By choosing a brand like Allmade for your company branded swag this means you’re putting your logo on tees that give back in a big way—not just to the planet but to the people who make each and every t-shirt.

				h2 Allmade’s Eco-Friendly Values and Impact 
				img(src='../../images/blog/allmade/allmade3.jpg' alt="Image of one of Allmade’s initiative's helping clothe children in Mexico.")
				p It's true that it gets more difficult to stay sustainable when you're running a large-scale operation, but this hasn't stopped Allmade. They are transparent about the factories that produce their apparel. They choose factories with good working conditions and proven commitment to sustainable practices.  
				p They set out to create a brand that didn't just talk the talk—it walked the walk. Through partnerships with organizations like the Global Organic Textile Standard (GOTS) and the Better Cotton Initiative (BCI), Allmade's making waves. From reforestation efforts to clean water initiatives, they're proving that fashion can be a force for good. 
				p On top of providing high-quality apparel that makes an impact, Allmade educates customers on sustainable living via their eco-friendly blog where they provide resources to help all their customers live greener lives.  
				p Allmade also promises to give back in the communities where their tees are manufactured. Most recently, they’ve created a GoFundMe for the Osovi Foster Home in Honduras. It caters specifically to little girls who are in desperate need. The Osovi Foster Home provides nutritional, psychological, and medical care, clothing, education, and basic needs. The Allmade Team is making a trip to Honduras where they’ll be putting in the labor to help with repair work and personally contribute to the list of items they need. A true testament to their belief in bringing more goodness to the world at a very local level.

				h2 Allmade's Eco-Friendly Tees
				img(src='../../images/blog/allmade/allmade4.png' alt="Allmade Women’s Relaxed Tri-Blend Scoop Neck Tee and Women’s Tri-Blend V-Neck Tee curated by Swagger")
				p Allmade's tees break the mold of typical corporate branded swag. Crafted from recycled polyester, organic cotton, and TENCEL™ Modal, they're a burst of originality. From tees to tanks, and a number of crazy-soft blends, Allmade lets companies make a statement—they're all about creating a positive impact.
				p And check this out—those tees? They're actually made from up to six recycled plastic water bottles, renewable trees, and organic cotton. A combination that reduces carbon waste and saves water. So not only are they ultra-comfy, but they're also like a high-five to the planet every time you rock one.  
				img(src='../../images/blog/allmade/allmade5.jpg' alt="Image of Allmade’s AL2100 Organic Tee can be company branded for you by Swagger")
				p Allmade also saves 145 gallons (about twice the size of a bathtub) of water each time they make one compared to your average t-shirt. Not only that, but just like Swagger, Allmade is carbon neutral. It’s safe to say, Allmade leaves regular corporate swag in the dust. 
				p Allmade has stayed committed to creating a covetable collection of tees that stay true to their original values of making quality products that are safe for the environment. By buying Allmade t-shirts for your company swag instead of fast-fashion tees, you're already making your carbon footprint smaller! 

				h2 Why Swagger Partners with Allmade 
				img(src='../../images/blog/allmade/allmade6.jpg' alt="Image of four colors of Allmade’s Unisex Organic French Terry Pullover Hoodie custom branded by Swagger")
				p The founders of Allmade set out on a mission to disrupt the t-shirt industry. They saw the negative impact of fast-fashion brands and how other t-shirts were (and are) filling landfills and slowly harming our planet.
				p For Swagger, teaming up with Allmade was a no-brainer. It's about more than just giving out throwaway tees—it's about making branded merch a statement. By choosing partners like Allmade, we too, can make a meaningful impact.
				p We do corporate branded swag differently. Swagger and Allmade have shared beliefs and goals: our foundation is grounded in sustainability, we create high-quality, thoughtful products with purpose, and protecting our planet is a priority. For more corporate swag inspo, check out Swagger’s <a href="/lookbooks/2024-trends">2024 Trends Lookbook</a>.
				p Are you ready to improve the way you do swag? Swagger is ready to partner with you. Fill out this short form: <a href="/get-started">Let’s Do This!</a>
</template>
  