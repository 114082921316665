<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/climate-neutral/climate-neutral1.jpg';

useHead({
	"title": "Swagger Achieves Climate Neutral Certification: A Greener Future.",
	"meta": [
		{
			"name": "description",
			"content": "Swagger celebrates becoming Climate Neutral Certified. Discover our commitment to reducing emissions, offsetting carbon, and creating a sustainable future for all."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/climate-neutral-certified"
		},
		{
			"property": "og:title",
			"content": "Swagger Achieves Climate Neutral Certification: A Greener Future."
		},
		{
			"property": "og:description",
			"content": "Swagger celebrates becoming Climate Neutral Certified. Discover our commitment to reducing emissions, offsetting carbon, and creating a sustainable future for all."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/climate-neutral-certified"
		},
		{
			"property": "og:image",
			"content": ""
		}
	]
});
</script>
<template lang="pug">
article.page.page--simple
	header.page__header
		h1 Swagger is Now Climate Neutral Certified: A Step Toward a Greener Future
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/climate-neutral/climate-neutral1.jpg')
				p At Swagger, we’re excited to announce that we have officially earned the <b>Climate Neutral Certified</b> label! This achievement marks a major step in our ongoing commitment to sustainability and reducing our environmental impact. This Certification is awarded to brands that take actionable steps to measure, reduce, and offset all of their carbon emissions, and we’re proud to be leading the charge in this important area of climate responsibility.
				
				h4 What Does Climate Neutral Certified Mean?
				img(src='../../images/blog/climate-neutral/climate-neutral2.jpg')
				p The <b>Climate Neutral Certified</b> label is not just a badge—it’s a standard that reflects our dedication to the planet. To earn this certification, we worked closely with the team at Change Climate to measure the carbon footprint of our operations and implement strategies to reduce and compensate for our emissions.
				p This certification empowers us to make a meaningful difference by reducing our carbon impact and investing in sustainable solutions. It also enables consumers to easily identify brands that are committed to tackling climate change head-on.

				h4 A Deep Dive into Swagger’s Carbon Emissions
				img(src='../../images/blog/climate-neutral/climate-neutral3.jpg')
				p To achieve <b>Climate Neutral Certification</b>, we took a deep dive into the emissions created by our company. This includes everything from the energy we consume to the products we deliver. Here’s a breakdown of Swagger’s carbon footprint:
				ul
					li <b>Total emissions:</b> 633 tCO2e (this is roughly the same as driving 141 gas-powered cars for an entire year)
					li <b>Emissions intensity:</b> 0.1 kgCO2e per dollar of revenue
					li <b>Scope 1 emissions:</b> 65 tCO2e (direct emissions like office heating and fleet vehicles)
					li <b>Scope 2 emissions:</b> 278 tCO2e (indirect emissions from electricity consumption)
					li <b>Scope 3 emissions:</b> 289 tCO2e (emissions from raw materials, manufacturing, shipping, and other supply chain activities)

				h4 Taking Action to Reduce Our Impact
				img(src='../../images/blog/climate-neutral/climate-neutral4.jpg')
				p Earning the <b>Climate Neutral Certified</b> label is just the beginning. At Swagger, we’re committed to further reducing our carbon emissions. In the next 12 to 24 months, we’ve set the following actionable goals:
				ul
					li <b>Packaging:</b> We will reduce emissions from project packaging, fillers, and other paper products by sourcing at least <b>20% post-consumer or reusable materials</b> by Q4 2025 (Scope 3).
					li <b>Electricity:</b> We’re launching an <b>employee emission-reduction program</b> for remote workers that will incentivize and fund energy-saving initiatives for their home offices by <b>Q1 2025</b> (Scope 2).
				p These are just the first steps in our ongoing efforts to make a tangible impact on our carbon footprint.
				
				h4 Long-Term Sustainability Goals
				img(src='../../images/blog/climate-neutral/climate-neutral5.jpg')
				p We’re not stopping there! Swagger has also set long-term sustainability targets that align with global climate science, aiming to reduce <b>Scope 1 &amp; 2 emissions by 50% by 2030</b>.
				p This goal is part of our larger commitment to ensure that our operations contribute to a healthier, more sustainable future for all.
				
				h4 Compensating for Emissions: Investing in Carbon Offsets
				img(src='../../images/blog/climate-neutral/climate-neutral6.jpg')
				p In addition to reducing our emissions, we’re committed to <b>offsetting the emissions we can’t eliminate.</b> For the past year, Swagger has invested in <b>high-quality, verified carbon credits</b> to compensate for our 633 tCO2e of emissions. These credits support vital <b>wind energy projects</b>, which help reduce our reliance on fossil fuels and promote sustainable energy solutions.

				h4 Transparency in Action: Following Swagger’s Climate Goals
				img(src='../../images/blog/climate-neutral/climate-neutral7.jpg')
				p We know that true sustainability requires transparency. That’s why Change Climate publishes a profile page for each certified brand, allowing you to track our emissions, offsets, and reduction action plans in real-time. We’re dedicated to staying on track with our climate goals, and you can follow our progress every step of the way.
				p <a href="https://explore.changeclimate.org/brand/swagger" target="_blank">Click here</a> to visit Swagger's profile page on the Change Climate website.
				p Swagger can help your company embrace sustainability in your branded merchandise. Whether you’re looking for eco-friendly products, carbon-neutral shipping, or purpose-driven swag for your team, we can help you create impactful swag that aligns with your corporate values.
				p Ready to make a lasting impact with your swag? Just fill out this short form: <a href="/lets-do-this">Let’s Do This</a>!
				p We’d love to hear from you! Drop us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>

				div(style="margin-top: 4em; display:flex; justify-content: center;")
					img(src='../../images/blog/climate-neutral/green-swagger-s.png')
</template>