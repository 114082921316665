
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/modernsprout/ms1.png';

useHead({
	"title": "Modern Sprout: Green Your Corporate Swag with Swagger",
	"meta": [
		{
			"name": "description",
			"content": "Uncover Modern Sprout, the brand redefining sustainable custom swag. From self-watering planters to eco-friendly kits, find out why Swagger loves Modern Sprout's green solutions."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/modern-sprout"
		},
		{
			"property": "og:title",
			"content": "Modern Sprout: Green Your Corporate Swag with Swagger"
		},
		{
			"property": "og:description",
			"content": "Uncover Modern Sprout, the brand redefining sustainable custom swag. From self-watering planters to eco-friendly kits, find out why Swagger loves Modern Sprout's green solutions."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/modern-sprout"
		},
		{
			"property": "og:image",
			"content": OgImage
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Brand We Love: Modern Sprout
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/modernsprout/ms1.png' alt="An assortment of Modern Sprout plant kits hanging in an apartment by the Modern Sprout Plant Mister curated by Swagger.")

				
				p Modern Sprout is the kind of brand that resonates with generations of eco-conscious individuals. Their commitment to sustainability, combined with state-of-the-art gardening solutions, makes Modern Sprout a brand you'll want to partner with for your custom swag needs. 
				p Modern Sprout prides itself on partnering with organizations that share the same commitment: building a better world. They're dedicated to understanding and working with the right people, partners, and organizations to turn their eco-friendly vision into action. And that’s exactly why Swagger partners with Modern Sprout. We’re obsessed with the idea of bringing more plants into urban areas and Modern Sprout does just that. They created a way to bring a little bit of the outside inside—where it’s needed most.
				p With Modern Sprout, you're not just getting eco-friendly products; you're joining a movement that values sustainability, innovation, and a commitment to the planet. Read on to discover why Modern Sprout is one of Swagger's top picks for sustainable branded merch. 
				
				h2 All About Modern Sprout
				img(src='../../images/blog/modernsprout/ms2.png' alt="Modern Sprout Sweet Yellow Pepper and Baby Watermelon grow kits.")
				p Modern Sprout sprouted from the minds of Sarah Burrows and Nick Behr, a dynamic duo with a shared passion for plants and sustainability. Faced with the challenges of keeping an indoor garden alive in their compact city apartment, Sarah and Nick saw a gap in the market for stylish, functional gardening products that were fit for urban living. 
				p Determined to bring their vision to life, they founded Modern Sprout, focusing on creating innovative solutions that make gardening accessible and enjoyable for everyone, regardless of their living space or gardening expertise. Their range of products for company branded swag, from self-watering planters to sleek indoor grow kits, reflects their commitment to blending form and function. 
				p As Modern Sprout has grown, so has its dedication to eco-friendly practices, a value deeply rooted in Sarah and Nick's original vision. Their journey from a small startup to a leading name in indoor gardening is a testament to their imaginative spirit and unwavering commitment to sustainability. 

				h2 How Modern Sprout Gives Back
				img(src='../../images/blog/modernsprout/ms3.png' alt="Plants that are a part of The One For One Tree Kit program by Modern Sprout.")
				p Modern Sprout is leading the way in sustainability, and their commitment extends far beyond their products. They're actively involved in initiatives that support environmental conservation and promote sustainable living.  
				p One of the ways Modern Sprout gives back is through their partnership with various environmental organizations. They're dedicated to supporting causes that align with their mission, from pollinator conservation to reforestation efforts. It's all about making a positive impact on the planet and encouraging others to do the same.  
				p We here at Swagger love the “One For One Tree Kit program”—where Modern Sprout donates to plant a tree through a partnership with American Forests for each kit purchased. It’s a way Modern Sprout brings greenery both to our indoor spaces and helps combat deforestation—we’re obsessed!
				p So, when you choose Modern Sprout for your corporate branded swag, you're not just getting high-quality, eco-friendly plants. You're also supporting a brand that's committed to making a difference in the world. 

				h2 Why Swagger Loves Modern Sprout 
				img(src='../../images/blog/modernsprout/ms4.png' alt="Modern Sprout Grow Live Well Set, Tiny Terracotta Grow Kit Champagne Poppies, Seed Bombs, Indoor Herb Garden Kit, Tapered Tumbler Grow Kit, and Plant Mister curated by Swagger.")
				p At Swagger, we're all about partnering with brands that genuinely live up to their values, and Modern Sprout is a perfect example. They're not just about being green; they're about making sustainability a part of everyday life. And their products? It's company swag that’s more than just pretty things to look at or urban department decor. They're a way to show that you care about our planet. 
				p What's cool about Modern Sprout is how they make being eco-friendly, stylish and easy. Whether it's their self-watering herb kits or their solar-powered grow lights, they've got something for everyone. So, when you choose Modern Sprout for your branded merchandise, you're choosing a brand that aligns with your commitment to a better world. 

				h2 Choose Modern Sprout for Your Eco-Friendly Corporate Gifts 
				img(src='../../images/blog/modernsprout/ms5.png' alt="The Modern Sprout Grounding Aloe plant in front of the Flowering plant by a candle on a table in an apartment can be custom branded and designed by Swagger.")
				p When you pick Modern Sprout for your company swag, it's like you're saying, "Hey, we care about the planet, and we want you to as well." You're not just giving a cool gift; you're inspiring your folks to embrace a more eco-friendly lifestyle. 
				p Let's face it, being green is in, and Modern Sprout is leading the charge. They've got this awesome range of products that blend style with sustainability. Imagine having a little herb garden right at your desk or a chic Grounding Aloe Kit that doubles as decor. That's what Modern Sprout is all about—making branded swag simple and fun to go green. 
				p Swagger brings all your swag needs under one roof. We create beautifully branded gifts, build ready-to-shop swag stores, and take care of kitting, warehousing, inventory, and on-demand shipping. We’re here to partner with you on stylish swag that they’ll want to show off and you’ll be proud to give. 

				p Looking for more company swag inspo? Dive into Swagger’s <a href="https://giveswagger.com/lookbooks/2024-trends">2024 Trends Lookbook</a>. 
				p Ready to treat your employees or clients to products that inspire them to live more sustainability? Just fill out this short form: <a href="/get-started">Let’s Do This!</a>
				p We’d love to hear from you! Drop us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>
</template>
  