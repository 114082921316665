export const routes = [
	{
		path: "/",
		name: "index",
		components: {
		default: () => import("@static/views/index.vue")
		}
	},
	{
		path: "/lookbooks",
		name: "lookbooks",
		children: [
		{
			path: "",
			name: "lookbooksIndex",
			components: {
			default: () => import("@static/views/lookbooks.vue")
			}
		},
		{
			path: ":slug",
			name: "lookbook",
			components: {
			default: () =>
				import("@static/scripts/components/lookbooks/LookbookShow.vue")
			}
		}
		]
	},
	{
		path: "/case-studies",
		children: [
		{
			path: "",
			name: "caseStudiesIndex",
			components: {
			default: () => import("@static/views/case-studies.vue")
			}
		},
		{
			path: ":slug",
			name: "caseStudy",
			components: {
			default: () => import("@static/scripts/components/caseStudies/CaseStudiesShow.vue")
			}
		}
		]
	},
	{
		path: "/about-us",
		name: "aboutUs",
		components: {
		default: () => import("@static/views/about-us.vue")
		}
	},
	{
		path: "/blog",
		name: "blog",
		components: {
		default: () => import("@static/views/blog.vue")
		},
		// children: [
		//   {
		//     path: ":slug",
		//     name: "post",

		//   }
		// ]
	},
	{
		path: "/brands-we-love",
		name: "brandsWeLove",
		components: {
		default: () => import("@static/views/brands-we-love.vue")
		}
	},
	{
		path: "/faqs",
		name: "faqs",
		components: {
		default: () => import("@static/views/faqs.vue")
		}
	},
	{
		path: "/get-started",
		name: "getStarted",
		components: {
		default: () => import("@static/views/get-started.vue")
		}
	},
	{
		path: "/our-work",
		name: "ourWork",
		components: {
		default: () => import("@static/views/our-work.vue")
		}
	},
	{
		path: "/our-impact",
		name: "ourImpact",
		components: {
		default: () => import("@static/views/our-impact.vue")
		}
	},
	{
		path: "/referral-thank-you",
		name: "referralThankYou",
		components: {
		default: () => import("@static/views/referral-thank-you.vue")
		}
	},
	{
		path: "/referral-program",
		name: "referralProgram",
		components: {
		default: () => import("@static/views/referral-program.vue")
		}
	},
	{
		path: "/swagger-services",
		name: "swaggerServices",
		components: {
		default: () => import("@static/views/swagger-services.vue")
		}
	},
	{
		path: "/thank-you",
		name: "thankYou",
		components: {
		default: () => import("@static/views/thank-you.vue")
		}
	},
	{
		path: "/privacy-policy",
		name: "privacyPolicy",
		components: {
		default: () => import("@static/views/legal/PrivacyPolicy.vue")
		}
	},
	{
		path: "/terms-of-service",
		name: "termsOfService",
		components: {
		default: () => import("@static/views/legal/TermsOfService.vue")
		}
	},
	{
		path: "/digital-marketing-manager",
		name: "digitalMarketingManager",
		components: {
		default: () =>
			import("@static/views/jobs/DigitalMarketingManager.vue")
		}
	},
	{
		path: "/graphic-designer",
		name: "graphicDesigner",
		components: {
		default: () => import("@static/views/jobs/GraphicDesigner.vue")
		}
	},
	{
		path: "/product-and-curation-specialist",
		name: "productAndCurationSpecialist",
		components: {
		default: () =>
			import("@static/views/jobs/ProductAndCurationSpecialist.vue")
		}
	},
	{
		path: "/purchasing-specialist",
		name: "purchasingSpecialist",
		components: {
		default: () => import("@static/views/jobs/PurchasingSpecialist.vue")
		}
	},
	{
		path: "/relationship-manager",
		name: "relationshipManager",
		components: {
		default: () => import("@static/views/jobs/RelationshipManager.vue")
		}
	},
	{
		path: "/sales-development-rep",
		name: "salesDevelopmentRep",
		components: {
		default: () => import("@static/views/jobs/SalesDevelopmentRep.vue")
		}
	}
];

// // dynamically build blog routes from files in @static/views/posts
const postsDir = import.meta.glob('./views/posts/*.vue', {eager: true});
Object.keys(postsDir).forEach( (key) => {
  const slug = key.replace('./views/posts/', '').replace(/\.\w+$/, '')
  routes.push({
    path: `/${slug}`,
    component: () => import(`@static/views/posts/${slug}.vue`)
  })
})