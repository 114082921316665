
<script setup>
import { useHead } from '@unhead/vue';
import OgImage from '../../images/blog/2023-impact/swagger-impact-graphic.png';

useHead({
	"title": "2023 Impact: Growth, Sustainability, &amp; Gratitude",
	"meta": [
		{
			"name": "description",
			"content": "As 2023 ends, we celebrate Swagger's global growth and the impact we've had. Your support drives our commitment to making swag that is covetable, cool, and sustainable."
		},
		{
			"rel": "canonical",
			"href": "https://giveswagger.com/2023-impact"
		},
		{
			"property": "og:title",
			"content": "2023 Impact: Growth, Sustainability, &amp; Gratitude"
		},
		{
			"property": "og:description",
			"content": "As 2023 ends, we celebrate Swagger's global growth and the impact we've had. Your support drives our commitment to making swag that is covetable, cool, and sustainable."
		},
		{
			"property": "og:url",
			"content": "https://giveswagger.com/2023-impact"
		},
		{
			"property": "og:image",
			"content": OgImage
		}
	]
});
</script>
<template lang="pug">

article.page.page--simple
	header.page__header
		h1 Our 2023 Impact
	.page__content
		section.section
			.section__content
				.featured
					img(src='../../images/blog/2023-impact/swagger-impact-graphic.png')
				
				p It's been a remarkable journey for Swagger, and we're proud of the growth and impact we've made. None of these accomplishments would have been possible without the support of our incredible partners and clients. We'd like to share a few of our most impactful milestones in 2023.

				h2 Our Swag Gives Back—Always
				p Sustainability remains at the heart of our Swagger values. We not only want to help our clients succeed in their sustainability efforts, but our gifting programs run even deeper—through our partnership with 1% for the Planet, we proudly supported 15 charities, contributing to positive environmental and social impact. 
				p Our growth also means significant impact in small areas. We plant a tree for every project we do, and this year alone helped to plant 4,300+ trees in urban areas—where human development and infrastructure often overshadows nature—a testament to our investment in a greener future and the preservation of biodiversity in large metropolises. 
				p We neutralized 100% of our carbon footprint by teaming up with the Carbon Fund—offsetting 300 metric tonnes of greenhouse gas emissions, equivalent to driving 750,000+ miles in an average car. 

				h2 Elevating Style Through Swagger's Thoughtful Curation
				p This year, Swagger grew our roster of partners to 470+ suppliers worldwide. With that growth, we're able to put more and more of the partners that make a difference in front of our clients. These include small businesses like the eco-friendly, women-owned blanket company, <a href="https://eco-hilana.com/pages/our-concept">Hilana</a>, and <a href="https://ritualchocolate.com/pages/our-story">Ritual Chocolate</a>, our favorite ethically-sourced chocolate bar on the planet.

				img(src='../../images/blog/2023-impact/hyatt-branded-picnic-basket-and-wine-chiller.png')
				p
					fig.fineprint Photo: Hilana blanket, picnic basket and S'well wine chiller with Hyatt logo
				
				p Our attention to detail leads to swag curation that included suppliers from diverse backgrounds, small businesses, and the sourcing of only high-quality products. Because what you put your logo on matters, and we're committed to making a difference.

				h2 End-to-End Swag Management
				p With hundreds of custom swag shops and shipping to over 82 countries, we're constantly pushing our ability to ship on-demand globally. And Swagger takes care of logistics to ensure swag gets to you safely no matter where you are in the world.
				p On top of that, we remain committed to ensuring all our packaging is completely curbside recyclable—from the box to the paper tape around your swag box.
				p And we celebrated a total of 31,674 gifts sent. We recognize this success reflects the trust and loyalty you've placed in Swagger to take care of all your swag needs. 
				p We appreciate you helping to support our quest to make swag that makes a difference. Here's to more goodness in 2024!
				p We'd love to hear from you! Drop us a note anytime at <a href="mailto:hello@giveswagger.com">hello@giveswagger.com</a>
</template>
  